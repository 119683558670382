import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import metaData from "../../constants/meta";
import canonicalDomains from "../../constants/canonicalDomains";
import SceneManager from "./SceneManager";
import "./index.scss";

export default class HomePage extends PureComponent {
  rendererContainer = React.createRef();

  componentDidMount() {
    this.sceneManager = new SceneManager(this.rendererContainer);
    this.sceneManager.init();
  }

  componentWillUnmount() {
    this.sceneManager.destroy();
  }

  render() {
    if (typeof document !== "undefined") {
      document.querySelector("nav.u_navigation").classList.add("page_404");
    }

    return (
      <div className="emptyPage">
        <Helmet>
          <title>{metaData.main.title}</title>
          <meta name="description" content={metaData.main.description} />
          <meta name="keywords" content={metaData.main.keywords} />
          <meta name="H1" content={metaData.main.h1} />
          {(canonicalDomains || []).map(domain => (
            <link href={domain.link} rel={domain.rel} hrefLang={domain.hreflang} key={domain.link} />
          ))}
        </Helmet>

        <section className="first-section">
          <div id="rendererContainer" ref={element => (this.rendererContainer = element)} />
          <div className="centered-section">
            <h1>Ooops, 404!</h1>
            <p className="description">Sorry, the page you were looking for could not be found.</p>
            <div className="buttons-wrapper">
              <Link to="/" className="buttons-first-section homePageBtn">
                Visit homepage
              </Link>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
