import {
  Scene,
  WebGLRenderer,
  BufferGeometry,
  PerspectiveCamera,
  Points,
  Float32BufferAttribute,
  PointsMaterial,
} from "three";

class SceneManager {
  constructor(renderContainer) {
    this.scene = new Scene();
    this.renderContainer = renderContainer;
    this.renderer = null;
    this.particles = [];
    this.count = 0;
    this.mouseX = -300; // 85
    this.mouseY = -250; //-342
    this.SEPARATION = 70;
    this.AMOUNTX = 100;
    this.AMOUNTY = 50;
    this.windowHalfX = 0;
    this.windowHalfY = 0;
  }

  // Инициализация сцены
  init = () => {
    this.buildRender();
    this.buildCamera();
    this.addParticles();
    this.animate();
    this.created();
  };

  setWindowSize = () => {
    if (typeof window !== "undefined") {
      this.windowHalfX = window.innerWidth / 2;
      this.windowHalfY = window.innerHeight / 2;
    }
  };

  // Создание рендера
  created = () => {
    if (typeof window !== "undefined") {
      window.addEventListener("resize", this.resize, false);
      window.addEventListener("mousemove", this.onDocumentMouseMove, false);
      // window.addEventListener("touchstart", this.onDocumentTouchStart, false);
      // window.addEventListener("touchmove", this.onDocumentTouchMove, false);
    }
  };

  // Удаление рендера
  destroy = () => {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.resize, false);
      window.removeEventListener("mousemove", this.onDocumentMouseMove, false);
      // window.removeEventListener("touchstart", this.onDocumentTouchStart, false);
      // window.removeEventListener("touchmove", this.onDocumentTouchMove, false);
    }
    cancelAnimationFrame(this.animationFrame);
  };

  // Постройка рендер контейнера
  buildRender = () => {
    this.renderer = new WebGLRenderer({ alpha: true, antialias: true });
    this.renderer.setSize(this.renderContainer.clientWidth, this.renderContainer.clientHeight);
    this.renderContainer.appendChild(this.renderer.domElement);
  };

  // Постройка камеры сцены
  buildCamera = () => {
    const width = this.renderContainer.clientWidth;
    const height = this.renderContainer.clientHeight;
    this.camera = new PerspectiveCamera(75, width / height, 1, 10000);
    this.camera.position.x = 1000; //1000
    this.camera.position.y = 800; //800
    this.camera.position.z = 500; //500
  };

  addParticles = () => {
    let numParticles = this.AMOUNTX * this.AMOUNTY;
    let positions = new Float32Array(numParticles * 3);
    let scales = new Float32Array(numParticles);
    let i = 0,
      j = 0;

    for (let ix = 0; ix < this.AMOUNTX; ix++) {
      for (let iy = 0; iy < this.AMOUNTY; iy++) {
        positions[i] = ix * this.SEPARATION - (this.AMOUNTX * this.SEPARATION) / 2; // x
        positions[i + 1] = 0; // y
        positions[i + 2] = iy * this.SEPARATION - (this.AMOUNTY * this.SEPARATION) / 2; // z
        scales[j] = 1;
        i += 3;
        j++;
      }
    }

    let geometry = new BufferGeometry();
    geometry.addAttribute("position", new Float32BufferAttribute(positions, 3));
    geometry.addAttribute("scale", new Float32BufferAttribute(scales, 1));
    geometry.computeBoundingSphere();

    let material = new PointsMaterial({ size: 1, color: 0xffffff });

    this.particles = new Points(geometry, material);
    this.scene.add(this.particles);

    // var geometry2 = new BoxGeometry(100, 100, 100);
    // var material2 = new MeshBasicMaterial({ color: 0x00ff00 });
    // var cube = new Mesh(geometry2, material2);
    // this.scene.add(cube);
  };

  // Анимация сцены
  animate = () => {
    this.animationFrame = requestAnimationFrame(this.animate);
    this.render();
  };

  // Ререндеринг сцены
  render = () => {
    this.camera.position.x += (this.mouseX - this.camera.position.x) * 0.05;
    const cameraY = this.camera.position.y + (-this.mouseY - this.camera.position.y) * 0.05;
    if (cameraY > 200 && cameraY < 800) {
      this.camera.position.y = cameraY;
    }

    this.camera.lookAt(this.scene.position);

    let positions = this.particles.geometry.attributes.position.array;
    let scales = this.particles.geometry.attributes.scale.array;

    let i = 0,
      j = 0;

    for (let ix = 0; ix < this.AMOUNTX; ix++) {
      for (let iy = 0; iy < this.AMOUNTY; iy++) {
        positions[i + 1] = Math.sin((ix + this.count) * 0.3) * 50 + Math.sin((iy + this.count) * 0.5) * 50;
        scales[j] = (Math.sin((ix + this.count) * 0.3) + 1) * 8 + (Math.sin((iy + this.count) * 0.5) + 1) * 8;
        i += 3;
        j++;
      }
    }

    this.particles.geometry.attributes.position.needsUpdate = true;
    this.particles.geometry.attributes.scale.needsUpdate = true;

    this.renderer.render(this.scene, this.camera);
    this.count += 0.1;
  };

  // Авто изменение размера сцены
  resize = () => {
    if (this.camera && this.renderer) {
      this.renderer.setSize(this.renderContainer.clientWidth, this.renderContainer.clientHeight);
    }
  };

  onDocumentMouseMove = event => {
    this.mouseX = event.clientX - this.windowHalfX;
    this.mouseY = event.clientY - this.windowHalfY;
  };

  onDocumentTouchStart = event => {
    if (event.touches.length === 1) {
      event.preventDefault();

      this.mouseX = event.touches[0].pageX - this.windowHalfX;
      this.mouseY = event.touches[0].pageY - this.windowHalfY;
    }
  };

  onDocumentTouchMove = event => {
    if (event.touches.length === 1) {
      event.preventDefault();

      this.mouseX = event.touches[0].pageX - this.windowHalfX;
      this.mouseY = event.touches[0].pageY - this.windowHalfY;
    }
  };
}

export default SceneManager;
